<aside id="aside" class="app-aside hidden-xs bg-blue">
      <div class="aside-wrap" id="sidebar">
            <div class="navi-wrap">
                  <!-- nav -->
                  <nav ui-nav class="navi clearfix" id="leftmenuinnerinner">
                        <ul class="nav">
                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>NAVIGATION</span>
                              </li>
                              <li> <a href="/dashboard" class="active"> <i class="glyphicon glyphicon-th-large"></i>
                                          <span>Dashboard</span>
                                    </a> </li>
                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>FARMER</span> </li>
                              <li> <a href="/dashboard/farmer/user"> <img
                                                src="../../../assets/img/bioveda-final-menu/farmer-register.svg"
                                                alt=""><span> Farmer's Registered</span> </a> </li>
                              <li> <a href="/dashboard/farmer/profile"> <img
                                                src="../../../assets/img/bioveda-final-menu/farmer-profile.svg"
                                                alt=""><span>Farmer Profile</span> </a> </li>
                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>ORDER
                                          INFORMATION</span> </li>
                              <li> <a href="/dashboard/purchase/order-view"> <img
                                                src="../../../assets/img/bioveda-final-menu/order-list.svg" alt="">
                                          <span> Order List View</span>
                                    </a> </li>
                              <li> <a href="/dashboard/purchase/delivery-view"> <img
                                                src="../../../assets/img/bioveda-final-menu/delivery-list.svg" alt="">
                                          <span> Delivery List
                                                View</span> </a></li>
                              <li> <a [routerLink]="['quick-delivery/delivery-person']"> <img
                                                src="assets/img/bioveda-final-menu/farmer-register.svg" alt="">
                                          <span>Delivering Persons</span> </a>
                              </li>
                              <!-- nav
          <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>NOTIFICATION</span> </li>
          <li> <a href="/dashboard/purchase/list-notification"> <img src="../../../assets/img/bioveda-final-menu/order-list.svg" alt="">
              <span>Add Notification</span>
            </a> </li>
            <li> <a href="/dashboard/purchase/sent-notification/send-list-notification"> <img src="../../../assets/img/bioveda-final-menu/order-list.svg" alt="">
                <span>Send Notification</span>
              </a> </li>-->
                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>KISAN MITRA</span>
                              </li>
                              <li> <a href="/dashboard/masters/kisanmitra"> <img
                                                src="../../../assets/img/bioveda-final-menu/kisan-mitra.svg"
                                                alt=""><span> Kisan Mitra List</span> </a>
                              </li>
                              <li> <a href="/dashboard/masters/kisanmitramapping"> <img
                                                src="../../../assets/img/bioveda-final-menu/mapping.svg" alt=""><span>
                                                Kisan Mitra
                                                Mapping</span> </a></li>
                              <li> <a href="/dashboard/masters/kisanmitraList"> <img
                                                src="../../../assets/img/bioveda-final-menu/village-km-code.svg"
                                                alt=""><span> Kisan Village</span> </a>
                              </li>
                              <li> <a href="/dashboard/purchase/distributor/list"> <img
                                                src="../../../assets/img/bioveda-final-menu/distributer.svg" alt="">
                                          <span> Distributor
                                          </span> </a></li>
                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>E-COMMERCE</span>
                              </li>
                              <li> <a href="/dashboard/purchase/product/list"> <img
                                                src="../../../assets/img/bioveda-final-menu/product.svg" alt="">
                                          <span>Product</span> </a>
                              </li>
                              <li> <a href="/dashboard/purchase/brand/list"> <img
                                                src="../../../assets/img/bioveda-final-menu/brnad.svg" alt=""> <span>
                                                Brand</span> </a> </li>
                              <li> <a href="/dashboard/purchase/productCategory/list"> <img
                                                src="../../../assets/img/bioveda-final-menu/product-category.svg"
                                                alt=""> <span>Product Category
                                          </span> </a> </li>
                              <li> <a href="/dashboard/purchase/subCategory/list"> <img
                                                src="../../../assets/img/bioveda-final-menu/product-usb-category.svg"
                                                alt=""> <span>Product Sub Category
                                          </span> </a> </li>

                              <li> <a href="/dashboard/purchase/taxRate/list"> <img
                                                src="../../../assets/img/bioveda-final-menu/tax-rate.svg" alt=""> <span>
                                                Tax Rate</span> </a>
                              </li>

                              <li> <a href="/dashboard/purchase/unit/list"> <img
                                                src="../../../assets/img/bioveda-final-menu/product-unit.svg" alt="">
                                          <span>Product Unit</span> </a>
                              </li>

                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>AGRI OUTPUT
                                          (SALE)</span> </li>
                              <li> <a href="/dashboard/sell/master/crop"> <img
                                                src="../../../assets/img/bioveda-final-menu/crop.svg" alt="">
                                          <span>Crop </span> </a> </li>
                              <li> <a href="/dashboard/sell/master/crop-type"><img
                                                src="../../../assets/img/bioveda-final-menu/crop-type.svg" alt="">
                                          <span>CropType</span> </a>
                              <li> <a href="/dashboard/sell/master/variety"> <img
                                                src="../../../assets/img/bioveda-final-menu/variety.svg" alt="">
                                          <span>Variety</span> </a>
                              </li>
                              <li> <a href="/dashboard/sell/master/mandi"> <img
                                                src="../../../assets/img/bioveda-final-menu/mandi.svg" alt="">
                                          <span>Mandi </span> </a> </li>
                              <li><a href="/dashboard/masters/mandimaster"> <img
                                                src="../../../assets/img/bioveda-final-menu/mandi-rate.svg" alt="">
                                          <span>Mandi Rate </span>
                                    </a> </li>
                              <li> <a href="/dashboard/sell/appointment"> <img
                                                src="../../../assets/img/bioveda-final-menu/appointment.svg"
                                                alt=""><span> Appointment</span> </a>
                              </li>
                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>SURVEY</span> </li>
                              <li> <a href="Javascript:void(0)"> <img
                                                src="../../../assets/img/bioveda-final-menu/order-list.svg" alt="">
                                          <span> Maize Crop Survey</span>
                                    </a> </li>
                              <li> <a [routerLink]="['quick-survey']"> <img
                                                src="../../../assets/img/bioveda-final-menu/order-list.svg" alt="">
                                          <span>Bioveda Quick Survey Report</span>
                                    </a> </li>
                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10 text-uppercase"> <span>Center
                                          Manager</span>
                              </li>
                              <li> <a href="/dashboard/center-manager/quality-parameter/list-template"> <img
                                                src="../../../assets/img/bioveda-final-menu/country.svg"
                                                alt=""><span>Parameter </span> </a> </li>
                              <li> <a href="/dashboard/center-manager/user-creation/list-user"> <img
                                                src="../../../assets/img/bioveda-final-menu/country.svg"
                                                alt=""><span>Centers </span> </a> </li>
                              <!-- <li> <a href="/dashboard/roles-and-permission/list-permission"> <img
                                                src="../../../assets/img/bioveda-final-menu/country.svg"
                                                alt=""><span>Roles & Permission </span> </a> </li> -->

                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10 text-uppercase"> <span>Roles
                                          Permission
                                          Manager</span>
                              </li>

                              <li> <a href="/dashboard/roles-and-permission/list-permission"> <img
                                                src="../../../assets/img/bioveda-final-menu/country.svg"
                                                alt=""><span>Roles & Permission </span> </a> </li>
                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>SYSTEM
                                          CONFIGURATION</span> </li>
                              <li> <a href="/dashboard/masters/country"> <img
                                                src="../../../assets/img/bioveda-final-menu/country.svg"
                                                alt=""><span>Country </span> </a> </li>
                              <li> <a href="/dashboard/masters/state"> <img
                                                src="../../../assets/img/bioveda-final-menu/state.svg" alt="">
                                          <span>State </span> </a> </li>
                              <li> <a href="/dashboard/masters/district"> <img
                                                src="../../../assets/img/bioveda-final-menu/district.svg" alt="">
                                          <span>District </span> </a> </li>
                              <li> <a href="/dashboard/masters/block"> <img
                                                src="../../../assets/img/bioveda-final-menu/block.svg" alt="">
                                          <span>Block </span> </a> </li>
                              <li> <a href="/dashboard/masters/village"> <img
                                                src="../../../assets/img/bioveda-final-menu/village.svg" alt="">
                                          <span>Village </span> </a> </li>
                              <li> <a href="/dashboard/farmer/sowingCropRes"> <img
                                                src="../../../assets/img/bioveda-final-menu/crop.svg" alt=""><span> Crop
                                                Response List</span>
                                    </a> </li>
                              <li> <a href="/dashboard"> <img src="../../../assets/img/bioveda-final-menu/product.svg"
                                                alt=""> <span>Produce
                                                Measuring Unit</span> </a> </li>
                              <li> <a href="/dashboard"> <img
                                                src="../../../assets/img/bioveda-final-menu/membership.png" alt="">
                                          <span>Membership </span> </a> </li>
                              <li> <a href="/dashboard"> <img src="../../../assets/img/bioveda-final-menu/livestock.png"
                                                alt="">
                                          <span>Livestock </span> </a> </li>
                              <li> <a href="/dashboard"> <img
                                                src="../../../assets/img/bioveda-final-menu/irregration.png" alt="">
                                          <span>Irrigation </span> </a> </li>
                              <li> <a href="/dashboard"> <img src="../../../assets/img/bioveda-final-menu/bank.png"
                                                alt=""><span>
                                                Bank</span> </a> </li>
                              <li> <a href="/dashboard/masters/language"> <img
                                                src="../../../assets/img/bioveda-final-menu/language.png" alt=""><span>
                                                Language </span> </a> </li>

                              <li class="line dk"></li>

                              <li class="hidden-folded padder m-t m-b-sm font-bold text-10"> <span>LOGOUT</span> </li>
                              <li> <a (click)="logout()"> <i class="fa fa-power-off"></i> <span>Logout </span> </a>
                              </li>
                              <li class="line dk"></li>
                        </ul>
                  </nav>
                  <!-- nav -->
                  <!-- aside footer -->
                  <div class="wrapper-sm poppins">
                        <div class="text-center-folded text-center">
                              <p class="hidden-folded text-xs m-b-xs">
                                    Need help ?
                                    <a href="https://www.troology.com/" target="_blank" class="font-weight-600">Contact
                                          us</a>
                              </p>
                              <p class="hidden-folded text-xs m-b-md">
                                    <a href="https://www.troology.com/" target="_blank"><i class="fa fa-bug"></i> Report
                                          a bug?</a>
                              </p>
                              <img src="../../assets/img/red.png" class="center-block img-responsive" width="75px" />

                              <hr style="margin: 10px 0;" />
                              <p class="hidden-folded text-10 font-weight-600 text-uppercase m-b-xs">
                                    <a href="">Support Manual</a>
                              </p>
                              <p class="hidden-folded text-10 font-weight-600 text-uppercase m-b-xs">
                                    <a href="">Copyright Information</a>
                              </p>
                        </div>
                  </div>
                  <!-- / aside footer -->
            </div>
      </div>
</aside>